import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Processing = () => {
  const processRef = useRef(null);
  useEffect(() => {
    if (processRef.current)
      processRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className="processing" id="result" ref={processRef}>
      <h1>Processing</h1>{" "}
      <FontAwesomeIcon icon={faSpinner} className="p-icon" />
    </div>
  );
};

export default Processing;
