import React, { useEffect } from "react";

const Error = ({ err_txt }) => {
  useEffect(() => {
    // hide the processing template
    localStorage.setItem("running", false);
  }, []);

  return (
    <div className="processing" id="result">
      <h1 style={{ color: "red" }}>{err_txt}</h1>
    </div>
  );
};

export default Error;
