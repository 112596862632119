import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { createSearchParams, useNavigate } from "react-router-dom";

const InstructionsModal = ({ credentials }) => {
  const [isChecked, setChecked] = useState(false);
  const navigate = useNavigate();

  function handleCheckbox() {
    setChecked(!isChecked);
  }

  function handleClr(e) {
    const check_label = document.getElementById("check_label");
    if (!isChecked) {
      check_label.style.color = "red";
    } else {
      navigate({
        pathname: "/assignment",
        search: createSearchParams({
          name: credentials[0],
          email: credentials[1],
        }).toString(),
      });
    }
  }

  return (
    <div className="ins_container">
      <div className="form__padding modal__image">
        <div className="form__image"></div>
      </div>
      <div className="ins_box">
        <h1>-: Instructions :-</h1>
        <ul className="">
        <strong>Read the instructions carefully before starting the test</strong>
          <br/>
          <li style={{ marginTop: "20px" }}>Please note Your test is being monitored.</li>
          <li style={{ marginTop: "20px" }}>Once you go to the next page, a website tour will start that will guide you through the functions of the Code Assessment portal. The tour looks like this:</li>
          <img src={require("../assets/driver_first.png")} alt="Website Tour" style={{ maxWidth: "700px", maxHeight: "700px", marginTop:"20px", marginBottom:"20px" }}/> 
          <li style={{ marginBottom: "20px" }}>Question appears on the left-hand side window.</li>
          <li style={{ marginBottom: "20px" }}>You have to write the code for the statement in the middle window, where you also get the option for choosing between various programming languages.</li>
          <li style={{ marginBottom: "20px" }}>On the right-side window, you can run the predefined test cases or test on your own custom input. Submit button submits the code.</li>
          
          <li>Once you have completed the tour by clicking next button or skipped the tour with the close button, a popup will appear and when clicked OK the timer for the assessment will start.</li>
          <img src={require("../assets/driver_second.png")} alt="Website Tour" style={{ maxWidth: "700px", maxHeight: "700px", marginTop:"20px", marginBottom:"20px"  }}/>
          
          <li style={{ marginBottom: "20px" }}>Attempt to login in another device will <strong>Logout</strong> from current session.</li>
          <li style={{ marginBottom: "20px" }}>On Refresh or ReLogin, You will lose your data.</li>
          <li style={{ marginBottom: "20px" }}>Your test will be automatically submitted after the completion of 45 min.</li>
          <li style={{ marginBottom: "20px" }}>You are allowed for multiple submissions.</li>
          <li style={{ marginBottom: "20px" }}><strong>Once You LOGOUT, you can't login again!</strong></li>
          
          <li style={{ marginBottom: "20px" }}>Before clicking on 'Submit' button, make sure to review your code.</li>     
          <li style={{ marginBottom: "20px" }}><strong>PLEASE NOTE: If you are unable to debug the errors, but you are confident about your code, please go ahead and submit. The code is checked manually by our team for logic and conceptual knowledge.</strong></li>
          <li> If your code is correct and all the test cases are correct, your screen will look like the image below. You can submit the test and logout. Your submission will be recorded.</li>  
          
          <img src={require("../assets/driver_third.png")} alt="Website Tour" style={{ maxWidth: "700px", maxHeight: "700px", marginBottom: "20px", marginTop:"20px"}}/>
          
          <br/>
          <h2 style={{ marginBottom: "20px" }}>Best of Luck !!</h2>
        </ul>
      </div>
      <div className="ins_forward flex">
        <div className="custom-input cl-white">
          <input
            type="checkbox"
            name="customInput"
            className="checkbox"
            style={{ display: "block" }}
            onChange={handleCheckbox}
          />
          <label
            htmlFor="customInput"
            style={{ color: "#7c7975" }}
            id="check_label"
          >
            <strong>I've read all the instructions.</strong>
          </label>
        </div>
        <a className="btn submitBtn " role="button" onClick={handleClr}>
          <FontAwesomeIcon icon={faArrowRight} />
        </a>
      </div>
    </div>
  );
};

export default InstructionsModal;
