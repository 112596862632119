import React from "react";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";

const LogoutBtn = ({ credentials, status }) => {
  const navigate = useNavigate();

  function logout() {
    var confirm = window.confirm("Do you really want to logout?");
    if (confirm) {
      
        localStorage.setItem("text", "You've already logged out!")
      
      // set time 0 for preventing further logout
      var submit_time = {
        email: credentials[1],
        time: 0,
        posttime: Date.now(),
      };
      axios
        .post("https://code.truliacare.com/backend/time", submit_time)
        .then((res) => {});

      var submit_log = {
        email: credentials[1],
        log: false,
        change: true,
        deleteAll: false,
      };
      axios
        .post("https://code.truliacare.com/backend/logout", submit_log, {
          withCredentials: true,
        })
        .then((res) => {
          console.log(res);
          // after submission
          // navigate to submission page
          // while sending the status
          navigate({
            pathname: "/submit",
            search: createSearchParams({
              stat: status,
            }).toString(),
          });
        });
    }
  }

  return (
    <button className="logoutBtn btn " onClick={logout}>
      Logout
    </button>
  );
};

export default LogoutBtn;
