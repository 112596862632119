import React, { useEffect, useState } from "react";
import InstructionsModal from "./InstructionsModal";
import axios from "axios";
import Pagenotfound from "./Pagenotfound";
import { useSearchParams } from "react-router-dom";

const Instructions = () => {
  const [logged, setLog] = useState(true);

  // getting the credentials from login page
  const [searchparams] = useSearchParams();
  const credentials = [searchparams.get("name"), searchparams.get("email")];

  async function getSession() {
    const session = await axios.get(
      'https://code.truliacare.com/backend/session/',
      { withCredentials: true }
    );
    if(!session.data.found  && credentials[1] !== 'satin@kratin.co.in'){
      setLog(false);
      localStorage.setItem("text", "You're already logged in Somewhere!")
    }else{
      setLog(true)
    }
  }
  // check for session change
  getSession();

  return (
    <>
      {logged ? (
        <div className="instruction">
          <InstructionsModal credentials={credentials} />
        </div>
      ) : (
        <Pagenotfound email={credentials[1]} />
      )}
    </>
  );
};

export default Instructions;
