import React from "react";

const Output = ({ input, yourOutput, expOutput, results, isChecked }) => {

  // decides if the execution passed the testcases or not
  var pass;
  results.every((val, i, arr) => val === "true")
    ? (pass = true)
    : (pass = false);

  var msg = pass ? "Correct Answer! " : "Wrong Answer";
  var para = pass
    ? `You have passed all the test cases! 
    Click the Submit button to run your code against all the test cases. `
    : "Try Again!";
  para = pass && expOutput === "" ? "Here is your output!" : "";
  var clr = pass ? "green" : "red";

  return (
    <div className="output editor-bgclr">
      <div className="message editor-bgclr">
        {/* Setting the color of text to show correct or wrong */}
        {!isChecked ? <h1 style={{ color: clr }}>{msg}</h1> : null}
        <p className="mt mb">{para}</p>
      </div>

      <div className="io bg-gray">
        {!isChecked ? (
          <>
            <div className="">
              <div>Compiler Message</div>
              <div className="comp-msg editor-bgclr pad-2 mt">{msg}</div>
            </div>
            <div className="">
              <div>Input</div>
              {/* toString() used to show array elements as string */}
              <p className="input editor-bgclr pad-2 mt">{input.toString()}</p>
            </div>
          </>
        ) : null}
        <div className="">
          <div>Your Output</div>
          <p className="your-output editor-bgclr pad-2 mt">
            {yourOutput.toString()}
          </p>
        </div>
        {!isChecked ? (
          <div className="">
            <div>Expected Output</div>
            <p className="exp-output editor-bgclr pad-2 mt">
              {expOutput.toString()}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Output;
