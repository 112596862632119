import React from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const Submission = () => {
  window.addEventListener("popstate", (e) => {
    // Nope, go back to your page
    this.props.history.go(1);
  });
  // Getting the status of code from editor page
  const [searchparams] = useSearchParams();
  var status = searchparams.get("stat");

  // removing data from localstorage
  localStorage.removeItem("code");

  // deciding status text
  var header, body;
  if (status === "Accepted") {
    header = "Congrats! Your code is accpeted!";
    body = "We will get back to you Soon!";
  } else {
    header = "Opps! It seems like your submission is not correct.";
    body = "Better luck next time!";
  }


  return (
    <div className="form cl-white">
      <h1>{header}</h1>
      <br />
      <h2>{body}</h2>
    </div>
  );
};

export default Submission;
