import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
const EditorHeader = ({ setDefaultValue, setLanguage, lang_id }) => {
  // set default value in drop down
  function setDefault() {
    setDefaultValue(true);
  }
  // choose lang from drop down
  function chooseLanguage(e) {
    var lang = e.target.value;
    lang_id(lang);

    switch (lang) {
      case "50":
        setLanguage("c");
        break;
      case "51":
        setLanguage("csharp");
        break;
      case "54":
        setLanguage("cpp");
        break;
      case "60":
        setLanguage("go");
        break;
      case "62":
        setLanguage("java");
        break;
      case "63":
        setLanguage("javascript");
        break;
      case "71":
        setLanguage("python");
        break;
      case "74":
        setLanguage("typescript");
        break;
      default:
        break;
    }
  }

  return (
    <div className="editor-header editor-bgclr flex">
      <div>
        <label htmlFor="select-language">Language</label>
        <select
          id="select-language"
          className="editor-element-height"
          onChange={chooseLanguage}
        >
          <option>Choose Language</option>
          <option value="50" mode="c">
            C
          </option>
          <option value="51" mode="csharp">
            C#
          </option>
          <option value="54" mode="cpp">
            C++
          </option>
          <option value="60" mode="go">
            Go
          </option>
          <option value="62" mode="java">
            Java
          </option>
          <option value="63" mode="javascript">
            JavaScript
          </option>
          <option value="71" mode="python">
            Python3
          </option>
        </select>
      </div>
      <button
        className="btn resetBtn editor-element-height bg-none"
        onClick={setDefault}
      >
        <FontAwesomeIcon icon={faArrowRotateRight} title="Reset Code" />
      </button>
    </div>
  );
};

export default EditorHeader;
