import React from "react";
import axios from "axios";

const Pagenotfound = ({ email }) => {
  function delete_login() {
    axios
      .delete(`https://code.truliacare.com/backend/delete/?email=${email}`, {
        withCredentials: true,
      })
      .then((res) => {
        window.location.replace("/");
      });
  }
  const text = localStorage.getItem("text")

  return (
    <div className="pagenotfound" style={{ width: "100vw", height: "100vh" }}>
      <h1>{text}</h1>
      <button onClick={delete_login}>
        Logout from other devices and Login
      </button>
    </div>
  );
};

export default Pagenotfound;
