import React, { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import jwt from "jwt-decode";

function CheckLogin() {
  const APP_URL = window.location.origin;
  const LOGOUT_URL = `https://uhx01.kratinmobile.com/uhx/identity/auth/dologout?callback=${APP_URL}`;

  const navigate = useNavigate();

  const location = useLocation();

  // navigate function
  const getTokenData = () => {
    const params = new URLSearchParams(location.hash);
    const hashData = JSON.parse(JSON.stringify([...params.entries()]));
    // localStorage.setItem('token', hashData[1][1]);
    return jwt(hashData[1][1]);
  };
  useEffect(() => {
    // remove localstorage on load of login
    localStorage.removeItem("code");
    saveUser();
  });

  // save credentials
  async function saveUser(response) {
    const tokenData = getTokenData();

    var email = tokenData.email;
    console.log(email);

    if (email === "satin@kratin.co.in") {
      navigate({
        pathname: "/instructions",
        search: createSearchParams({
          name: tokenData.given_name + " " + tokenData.family_name,
          email: email,
        }).toString(),
      });
    } else {
      // get if the user is already logged in
      //  delete all other sessions
      axios
        .delete(`https://code.truliacare.com/backend/delete/?email=${email}`, {
          withCredentials: true,
        })
        .then((res) => {});

      // check for time exhuasted
      // if exhasted can't login more
      const time = await axios.get(
        `https://code.truliacare.com/backend/time/?email=${email}`
      );
      var pt = time.data.post_time;
      var t = time.data.time;
      // console.log(t, pt);
      var updated_time = t - Math.floor((Date.now() - pt) / 1000);
      // console.log(updated_time);
      if (updated_time <= 0) {
        localStorage.setItem("text", "Your time has exhausted!");

        // const ok = window.alert(
        //   "Your time has EXHAUSTED!\n Can't Login again!"
        // );
        // console.log(ok)
        if (window.confirm(
          "Your time has EXHAUSTED!\n Can't Login again!"
        )) window.location.replace(LOGOUT_URL);
      } else if (updated_time > 0 || t === undefined) {
        // if time hasn't exhausted, login
        var submit_log = {
          email: email,
          log: true,
          change: true,
        };
        axios
          .post("https://code.truliacare.com/backend/session", submit_log, {
            withCredentials: true,
          })
          .then((response) => {});

        await new Promise((resolve) => setTimeout(resolve, 500));

        navigate({
          pathname: "/instructions",
          search: createSearchParams({
            name: tokenData.given_name + " " + tokenData.family_name,
            email: email,
          }).toString(),
        });
      }
    }
  }

  // show error
  const respondError = (err) => {
    window.alert("Not a valid email!");
  };
}

export default CheckLogin;
